import "@stimulus/polyfills";

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import {
  controllers,
  registerControllers,
} from "@ddy-dmtw/view-component-stimulus";
import { Application } from "stimulus";

const stimulusApplication = Application.start();
registerControllers(stimulusApplication, controllers);

Turbolinks.start();
Rails.start();

// CSRF and auto_respond form logic
document.addEventListener("turbolinks:load", () => {
  Rails.refreshCSRFTokens();

  if (document.querySelector('#auto_respond_form')) {
    document.querySelector('body').style.display = 'none';
    document.forms['auto_respond_form'].submit();
  }
});
